.TokenSelector .Modal .Modal-content {
  width: 20rem;
  margin: 0;
  max-height: 100vh;
  border: 1px solid var(--cell-highlight)!important;
}

.TokenSelector-token-name {
  margin-right: 1rem;
}

.TokenSelector.disabled {
  pointer-events: none;
}

.TokenSelector.disabled .TokenSelector-caret {
  display: none;
}

.TokenSelector-box {
  display: block;
  cursor: pointer;
  border-radius: 6px;
  text-align: right;
  white-space: nowrap;
}

.TokenSelector-box:hover {
  color: var(--text-hover);
}

.Token-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Token-info .Token-symbol {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
}
.Token-balance {
  display: flex;
  flex-direction: column;
  text-align: right;
}
.Token-info .token-logo {
  display: block !important;
  width: 40px;
  height: 40px;
  margin-right: 2px;
  margin-left: 0 !important;
}

.TokenSelector-caret {
  vertical-align: middle;
  display: inline-block;
  margin-left: 0.3rem;
  font-size: 1.5rem;
  margin-top: -0.3rem;
}

.TokenSelector-token-row {
  cursor: pointer;
  border: 1px solid var(--cell-stroke);
  background: transparent;
  border-radius: var(--border-radius-sm);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin-bottom: 8px;
}

.TokenSelector-token-row.TokenSelector-token-input-row {
  padding: 0;
}

.TokenSelector-token-row input {
  padding: 8px;
  width: 100%;
}

.TokenSelector-token-row .Token-text {
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 0px;
}
.TokenSelector-token-row:hover {
  background: var(--dark-blue-hover);
}

.TokenSelector.right .Modal {
  align-items: flex-start;
  justify-content: flex-end;
}
.text-accent {
  color: var(--dark-blue-accent);
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0;
}

.TokenSelector.right .Modal-content {
  position: absolute;
  width: 23rem;
  top: 0;
  bottom: 0;
  overflow: auto;
  border: none;
}

@media (max-width: 700px) {
  .TokenSelector.right .Modal {
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 2rem;
    top: 0;
    right: 2rem;
    bottom: 0;
  }
  .Token-info .token-logo {
    width: 30px;
    height: 30px;
    margin-right: 0.5rem;
  }
}
