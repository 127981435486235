.menu-items:focus-visible {
  border: 1px solid #262638;
}
.address-btn {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  color: var(--text-navigation) !important;
  background: var(--background-navigation) !important;
  transition: background 0.3s ease;
  height: 36px;
  border-radius: 4px !important;
}
.address-btn:hover {
  background: var(--action-active) !important;
}
.user-address {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px !important;
  margin-right: auto;
}

.App-header-user-address:hover {
  background: #808aff14;
}

.menu-items {
  position: absolute;
  right: 0;
  top: calc(100% + 0.5rem);
  min-width: 10rem;
  width: 100%;
  transform-origin: top right;
  border-radius: 4px;
  background: var(--background-gradient);
  border: 1px solid var(--cell-highlight);
  list-style: none;
  cursor: pointer;
  outline: none;
  z-index: 1000;
  overflow: hidden;
}
.menu-item {
  display: flex !important;
  align-items: center;
  font-size: 1rem;
  color: white;
  padding: 8.5px 8px;
  font-size: 14px;
  line-height: 150%;
  border-bottom: 1px solid var(--cell-highlight);
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  transition: background-color 0.3s ease;
  background-color: transparent;
}
.menu-item:hover {
  background-color: var(--action-stroke) !important;
}
.menu-item img {
  filter: invert(1) brightness(100);
}
.menu-item.large {
  padding: 16px;
  font-size: 16px;
}
.menu-item.menu-item-selected {
  background-color: var(--action-stroke) !important;
}
.menu-item:last-child {
  border-bottom: 0;
}
.menu-item > p {
  margin: 0px;
  white-space: nowrap;
}
.menu-item > img {
  margin-right: 8px;
}
.menu-item > p {
  margin-right: 8px;
}
.menu-item > a {
  display: inline-flex;
}

@media screen and (max-width: 1380px) {
  .user-address {
    display: none;
  }
  .user-address + svg {
    margin-left: 8px;
  }
  .menu-items {
    min-width: 12rem;
  }
}

@media screen and (max-width: 370px) {
  .user-address {
    display: none;
  }
  .address-btn {
    display: flex;
    width: 60px;
    justify-content: space-between;
  }
}
