/* Downloaded from https://google-webfonts-helper.herokuapp.com/fonts/inter */

/* inter-regular - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/inter/inter-v11-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("./fonts/inter/inter-v11-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/inter/inter-v11-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/inter/inter-v11-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/inter/inter-v11-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/inter/inter-v11-latin-regular.svg#Inter") format("svg"); /* Legacy iOS */
}

/* inter-600 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/inter/inter-v11-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("./fonts/inter/inter-v11-latin-600.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/inter/inter-v11-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/inter/inter-v11-latin-600.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/inter/inter-v11-latin-600.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/inter/inter-v11-latin-600.svg#Inter") format("svg"); /* Legacy iOS */
}

/* inter-700 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/inter/inter-v12-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("./fonts/inter/inter-v12-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/inter/inter-v12-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/inter/inter-v12-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/inter/inter-v12-latin-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/inter/inter-v12-latin-700.svg#Inter") format("svg"); /* Legacy iOS */
}
