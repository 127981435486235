.Tooltip {
  position: relative;
}

.Tooltip-popup {
  font-size: 0.9rem;
  line-height: 1.032rem;
  position: absolute;
  border-radius: 4px;
  padding: 0.674rem;
  letter-spacing: 0.4px;
  min-width: 250px;
  text-align: left;

  background: var(--tooltip-fill);
  border: 1px solid var(--cell-stroke);
  box-shadow: var(--tooltip-shadow);

  z-index: 1000;
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.3s ease;
}

.Tooltip-popup.visible {
  opacity: 1;
  pointer-events: auto;
}

.Tooltip.nowrap .Tooltip-popup {
  min-width: auto;
  white-space: nowrap;
}

.Tooltip-popup :first-child {
  margin-top: 0;
}

.Tooltip-popup :last-child {
  margin-bottom: 0;
}

.Tooltip-popup.left-bottom {
  left: 0;
  transform: translateY(0.5rem);
  top: 100%;
}

.Tooltip-popup.right-bottom {
  right: 0;
  transform: translateY(0.5rem);
  top: 100%;
}

.Tooltip-popup.right-top {
  right: 0;
  transform: translateY(-0.5rem);
  bottom: 100%;
}
.Tooltip-popup.right {
  left: 30px;
  transform: translateY(50%);
  bottom: 100%;
}

.Tooltip-popup.left-top {
  left: 0;
  transform: translateY(-0.5rem);
  bottom: 100%;
}

.Tooltip-popup.center-bottom {
  left: 50%;
  transform: translateY(0.5rem) translateX(-50%);
  top: 100%;
}

.Tooltip-popup.center-top {
  left: 50%;
  transform: translateY(-0.5rem) translateX(-50%);
  bottom: 100%;
}

.Tooltip-handle {
  cursor: help;
  position: relative;
  display: inline-flex;
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  text-decoration-color: rgba(255, 255, 255, 0.6);
}

.Tooltip-handle.plain:after {
  border-bottom: none;
}

.Tooltip.no-underline .Tooltip-handle::after {
  content: unset;
}

@media (max-width: 700px) {
  .Tooltip-popup {
    min-width: 200px;
  }
}
.Tooltip-coming-soon {
  position: relative;
  z-index: 1000;
}
.Tooltip-coming-soon .Tooltip-popup {
  color: var(--text-primary);
  white-space: nowrap;
  min-width: fit-content;
}
